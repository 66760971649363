import { AiOutlineClose } from "react-icons/ai";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router";

import { ConDesignItemLite } from "../../types/ConDesignItem";
import { getLoginRedirectUri } from "../../utils/redirectUriUtils";

interface Props {
  item: ConDesignItemLite;
  handleCloseModal: () => void;
}

const ExpiredTokenModal = ({ item, handleCloseModal }: Props) => {
  const location = useLocation();
  const redirectUri = getLoginRedirectUri(location.pathname, `/item/${item.id}`);

  return (
    <div className="modal">
      <div className="modal_background" onClick={handleCloseModal}></div>
      <div className="modal_wrapper">
        <div className="close-icon_row">
          <div className="close-icon">
            <AiOutlineClose onClick={handleCloseModal} size={25} />
          </div>
        </div>

        <div className="modal_wrapper_content">
          <div className="modal_image">
            <img src={item.pictureUrl} alt="design item" />
          </div>
          <div className="modal_content_container">
            <p className="modal_text_center_aligned">
              <FormattedMessage
                id="ModalExpiredToken_sign_in"
                defaultMessage="You have been signed out due to inactivity."
              />
            </p>
            <p className="modal_text_center_aligned">
              <FormattedMessage
                id="ModalExpiredToken_sign_in_continue"
                defaultMessage="Sign in to continue."
              />
            </p>
            <a
              className="signup_link"
              href={redirectUri}
              rel="noreferrer"
            >
              <FormattedMessage id="SignIn" defaultMessage="Sign in" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpiredTokenModal;
